.video-js .vjs-menu-button-inline.vjs-slider-active, .video-js .vjs-menu-button-inline:focus, .video-js .vjs-menu-button-inline:hover, .video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 10em
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
    display: none !important
}

.video-js .vjs-control {
    width: 3em
}

.video-js .vjs-menu-button-inline:before {
    width: 1.5em
}

.vjs-menu-button-inline .vjs-menu {
    left: 3em
}

.video-js.vjs-paused .vjs-big-play-button, .vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
    display: block
}

.video-js .vjs-load-progress div, .vjs-seeking .vjs-big-play-button, .vjs-waiting .vjs-big-play-button {
    display: none !important
}

.video-js .vjs-mouse-display:after, .video-js .vjs-play-progress:after {
    padding: 0 .4em .3em
}

.video-js.vjs-ended .vjs-loading-spinner {
    display: none
}

.video-js.vjs-ended .vjs-big-play-button {
    display: block !important
}

.video-js *, .video-js:after, .video-js:before {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    box-sizing: inherit;
}

.video-js.vjs-fullscreen, .video-js.vjs-fullscreen .vjs-tech {
    width: 100% !important;
    height: 100% !important
}

.video-js {
    overflow: hidden;
    font-size: 14px;
}

.video-js .vjs-control {
    color: inherit
}

.video-js .vjs-menu-button-inline:hover, .video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 8.35em
}

.video-js .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
    width: 6.35em;
    height: 3em;
}

.video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before {
    text-shadow: 0 0 1em #fff, 0 0 1em #fff, 0 0 1em #fff
}

.video-js .vjs-spacer, .video-js .vjs-time-control {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
}

.video-js .vjs-time-control {
    -ms-flex: 0 1 auto;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    width: auto;
    -webkit-box-flex: 0 1 auto;
    -moz-box-flex: 0 1 auto;
}

.video-js .vjs-time-control.vjs-time-divider {
    width: 14px
}

.video-js .vjs-time-control.vjs-time-divider div {
    width: 100%;
    text-align: center
}

.video-js .vjs-time-control.vjs-current-time {
    margin-left: 1em
}

.video-js .vjs-time-control .vjs-current-time-display, .video-js .vjs-time-control .vjs-duration-display {
    width: 100%
}

.video-js .vjs-time-control .vjs-current-time-display {
    text-align: right
}

.video-js .vjs-time-control .vjs-duration-display {
    text-align: left
}

.video-js .vjs-play-progress:before, .video-js .vjs-progress-control .vjs-play-progress:before, .video-js .vjs-remaining-time, .video-js .vjs-volume-level:after, .video-js .vjs-volume-level:before, .video-js.vjs-live .vjs-time-control.vjs-current-time, .video-js.vjs-live .vjs-time-control.vjs-duration, .video-js.vjs-live .vjs-time-control.vjs-time-divider, .video-js.vjs-no-flex .vjs-time-control.vjs-remaining-time {
    display: none
}

.video-js.vjs-no-flex .vjs-time-control {
    display: table-cell;
    width: 4em
}

.video-js .vjs-progress-control {
    width: 100%;
    height: .5em;
    position: absolute;
    top: -.5em;
    right: 0;
    left: 0;
}

.video-js .vjs-progress-control .vjs-load-progress, .video-js .vjs-progress-control .vjs-play-progress, .video-js .vjs-progress-control .vjs-progress-holder {
    height: 100%
}

.video-js .vjs-progress-control .vjs-progress-holder {
    margin: 0
}

.video-js .vjs-progress-control:hover {
    height: 1.5em;
    top: -1.5em
}

.video-js .vjs-control-bar {
    -moz-transition: -moz-transform .1s ease 0s;
    -ms-transition: -ms-transform .1s ease 0s;
    -o-transition: -o-transform .1s ease 0s;
    -webkit-transition: -webkit-transform .1s ease 0s;
    transition: transform .1s ease 0s
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar, .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar, .video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
    visibility: visible;
    opacity: 1;
    -moz-transform: translateY(3em);
    -ms-transform: translateY(3em);
    -o-transform: translateY(3em);
    -webkit-transform: translateY(3em);
    transform: translateY(3em);
    -moz-transition: -moz-transform 1s ease 0s;
    -ms-transition: -ms-transform 1s ease 0s;
    -o-transition: -o-transform 1s ease 0s;
    -webkit-transition: -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s;
    -webkit-backface-visibility: hidden;
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control, .video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
    height: .25em;
    top: -.25em;
    -moz-transition: height 1s, top 1s;
    -ms-transition: height 1s, top 1s;
    -o-transition: height 1s, top 1s;
    -webkit-transition: height 1s, top 1s;
    transition: height 1s, top 1s;
    pointer-events: none;
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control, .video-js.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
    opacity: 0;
    -moz-transition: opacity 1s ease 1s;
    -ms-transition: opacity 1s ease 1s;
    -o-transition: opacity 1s ease 1s;
    -webkit-transition: opacity 1s ease 1s;
    transition: opacity 1s ease 1s
}

.video-js.vjs-live .vjs-live-control {
    margin-left: 1em
}

.video-js .vjs-big-play-button {
    margin-top: -1em !important;
    margin-left: -1em;
    border: none;
    width: 2em;
    height: 2em !important;
    top: 50%;
    left: 50%;
    color: #fff;
    background-color: rgba(0, 0, 0, .45);
    border-radius: 50%;
    font-size: 3.5em;
    line-height: 2em !important;
    -moz-transition: border-color .4s, outline .4s, background-color .4s;
    -ms-transition: border-color .4s, outline .4s, background-color .4s;
    -o-transition: border-color .4s, outline .4s, background-color .4s;
    -webkit-transition: border-color .4s, outline .4s, background-color .4s;
    transition: border-color .4s, outline .4s, background-color .4s;
}

.video-js .vjs-menu-button-popup .vjs-menu {
    left: -3em
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    padding-bottom: .5em;
    width: 12em;
    left: -1.5em;
    background-color: transparent;
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item, .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
    margin: .3em 0;
    padding: .5em;
    background-color: #151b17;
    border-radius: .3em
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    background-color: #2483d5
}

.video-js .vjs-big-play-button:active, .video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button {
    background-color: rgba(36, 131, 213, .9)
}

.video-js .vjs-loading-spinner {
    border-color: rgba(36, 131, 213, .8)
}

.video-js .vjs-control-bar2 {
    background-color: #000
}

.video-js .vjs-control-bar {
    color: #fff;
    background-color: rgba(0, 0, 0, .3) !important;
    font-size: 14px
}

.video-js .vjs-play-progress, .video-js .vjs-volume-level {
    background-color: #2483d5
}
