/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-BoldItalic.eot');*/
/*  src: local('Avenir Next Cyr Bold Italic'), local('AvenirNext-BoldItalic'),*/
/*  url('/fonts/avenir/AvenirNext-BoldItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-BoldItalic.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-BoldItalic.ttf') format('truetype');*/
/*  font-weight: bold;*/
/*  font-style: italic;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-Light.eot');*/
/*  src: local('Avenir Next Cyr Light'), local('AvenirNext-Light'),*/
/*  url('/fonts/avenir/AvenirNext-Light.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-Light.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-Light.ttf') format('truetype');*/
/*  font-weight: 300;*/
/*  font-style: normal;*/
/*}*/

@font-face {
    font-family: 'Avenir Next';
    src: url('/fonts/avenir/AvenirNext-Regular.eot');
    src: url('/fonts/avenir/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
        /* url('/fonts/avenir/AvenirNext-Regular.woff2') format('woff2'),*/ url('/fonts/avenir/AvenirNext-Regular.woff') format('woff'),
    url('/fonts/avenir/AvenirNext-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('/fonts/avenir/AvenirNext-Medium.eot');
    src: url('/fonts/avenir/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
        /* url('/fonts/avenir/AvenirNext-Medium.woff2') format('woff2'),*/ url('/fonts/avenir/AvenirNext-Medium.woff') format('woff'),
    url('/fonts/avenir/AvenirNext-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('/fonts/avenir/AvenirNext-Demi.eot');
    src: url('/fonts/avenir/AvenirNext-Demi.eot?#iefix') format('embedded-opentype'),
        /* url('/fonts/avenir/AvenirNext-Demi.woff2') format('woff2'),*/ url('/fonts/avenir/AvenirNext-Demi.woff') format('woff'),
    url('/fonts/avenir/AvenirNext-Demi.ttf') format('truetype');
    font-weight: 600;
    font-weight: 600 1000;
    font-style: normal;
}

/*@font-face {*/
/*    font-family: 'Avenir Next';*/
/*    src: url('/fonts/avenir/AvenirNext-Bold.eot');*/
/*    src: url('/fonts/avenir/AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),*/
/*    // url('/fonts/avenir/AvenirNext-Bold.woff2') format('woff2'),*/
/*    url('/fonts/avenir/AvenirNext-Bold.woff') format('woff'),*/
/*    url('/fonts/avenir/AvenirNext-Bold.ttf') format('truetype');*/
/*    font-weight: 700;*/
/*    font-style: normal;*/
/*}*/


/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-ThinItalic.eot');*/
/*  src: local('Avenir Next Cyr Thin Italic'), local('AvenirNext-ThinItalic'),*/
/*  url('/fonts/avenir/AvenirNext-ThinItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-ThinItalic.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-ThinItalic.ttf') format('truetype');*/
/*  font-weight: 100;*/
/*  font-style: italic;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-Italic.eot');*/
/*  src: local('Avenir Next Cyr Italic'), local('AvenirNext-Italic'),*/
/*  url('/fonts/avenir/AvenirNext-Italic.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-Italic.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-Italic.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: italic;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-HeavyItalic.eot');*/
/*  src: local('Avenir Next Cyr Heavy Italic'), local('AvenirNext-HeavyItalic'),*/
/*  url('/fonts/avenir/AvenirNext-HeavyItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-HeavyItalic.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-HeavyItalic.ttf') format('truetype');*/
/*  font-weight: 900;*/
/*  font-style: italic;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-MediumItalic.eot');*/
/*  src: local('Avenir Next Cyr Medium Italic'), local('AvenirNext-MediumItalic'),*/
/*  url('/fonts/avenir/AvenirNext-MediumItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-MediumItalic.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-MediumItalic.ttf') format('truetype');*/
/*  font-weight: 500;*/
/*  font-style: italic;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-LightItalic.eot');*/
/*  src: local('Avenir Next Cyr Light Italic'), local('AvenirNext-LightItalic'),*/
/*  url('/fonts/avenir/AvenirNext-LightItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-LightItalic.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-LightItalic.ttf') format('truetype');*/
/*  font-weight: 300;*/
/*  font-style: italic;*/
/*}*/


/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-Heavy.eot');*/
/*  src: local('Avenir Next Cyr Heavy'), local('AvenirNext-Heavy'),*/
/*  url('/fonts/avenir/AvenirNext-Heavy.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-Heavy.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-Heavy.ttf') format('truetype');*/
/*  font-weight: 900;*/
/*  font-style: normal;*/
/*}*/


/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-DemiItalic.eot');*/
/*  src: local('Avenir Next Cyr Demi Italic'), local('AvenirNext-DemiItalic'),*/
/*  url('/fonts/avenir/AvenirNext-DemiItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-DemiItalic.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-DemiItalic.ttf') format('truetype');*/
/*  font-weight: bold;*/
/*  font-style: italic;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Avenir Next';*/
/*  src: url('/fonts/avenir/AvenirNext-Thin.eot');*/
/*  src: local('Avenir Next Cyr Thin'), local('AvenirNext-Thin'),*/
/*  url('/fonts/avenir/AvenirNext-Thin.eot?#iefix') format('embedded-opentype'),*/
/*  url('/fonts/avenir/AvenirNext-Thin.woff') format('woff'),*/
/*  url('/fonts/avenir/AvenirNext-Thin.ttf') format('truetype');*/
/*  font-weight: 100;*/
/*  font-style: normal;*/
/*}*/
