@layer components {
    .container {
        @apply m-auto;
        width: calc(100% - theme("spacing.6"));

        @screen md {
            width: calc(100% - theme("spacing.12"));
        }

        @screen xl {
            width: 1260px;
        }
    }
}
