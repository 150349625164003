@import "fonts.pcss";

@import "libs/vsg-skin.css";

@import "keyframes.pcss";
@import "animations.pcss";


@import "tailwind.pcss";
@import "ui/container.pcss";
@import "ui/article-styles.pcss";
@import "ui/loading.pcss";

:root {
    --tw-bg-opacity: 1;
    --tw-border-opacity: 1;
    --tw-divide-opacity: 1;
    --tw-ring-opacity: 1;
    --tw-text-opacity: 1;
}

html {
    font-size: 16px;
    color: rgb(77, 87, 102);
    word-wrap: break-word;
    word-break: break-word;
}

body {
    font-size: 14px;
    -webkit-touch-callout: none !important;
}

* {
    outline: none !important;
}

h1 {
    font-size: 1.75rem;
    font-weight: bold;
}

h2 {
    font-size: 1.5rem;
    font-weight: bold;
}

h3 {
    font-size: 1.25rem;
    font-weight: bold;
}

/**
    Debug plugin fix
 */

.debug-screens::before {
    opacity: .3;
    border: none;
    box-shadow: none;
    pointer-events: none;
}

.unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

deepl-input-controller{
    display: none !important;
}

@media only screen and (max-device-width: 667px), screen and (max-width: 450px) {
    body .carrotquest-css-reset .carrot-messenger-frame-container {
        top: 0!important;
        bottom: 0!important;;
        left: 0!important;;
        right: 0!important;;
    }
}
