.zoom-in-top-enter-active, .zoom-in-top-leave-active {
    opacity: 1;
    transform: scaleY(1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
    transform-origin: center top
}

.zoom-in-top-enter, .zoom-in-top-leave-active {
    opacity: 0;
    transform: scaleY(0)
}

.zoom-in-top-enter-active, .zoom-in-top-leave-active {
    opacity: 1;
    transform: scaleY(1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
    transform-origin: center top
}

.zoom-in-top-enter, .zoom-in-top-leave-active {
    opacity: 0;
    transform: scaleY(0)
}

.zoom-in-enter-active, .zoom-in-leave-active {
    opacity: 1;
    transform: scale(1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
    transform-origin: center top
}

.zoom-in-enter, .zoom-in-leave-active {
    opacity: 0;
    transform: scale(0)
}

.zoom-in-enter-active, .zoom-in-leave-active {
    opacity: 1;
    transform: scale(1);
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
    transform-origin: top left;
}

.zoom-in-enter, .zoom-in-leave-active {
    opacity: 0;
    transform: scale(0)
}

.fade-in-linear-enter-active,
.fade-in-linear-leave-active {
    transition: opacity 0.2s linear;
}

.fade-in-linear-enter,
.fade-in-linear-leave,
.fade-in-linear-leave-active {
    opacity: 0;
}

.fade-in-enter-active,
.fade-in-leave-active {
    transition: opacity 0.2s ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
    opacity: 0;
}
