@layer components {
    .article-styles {
        line-height: 1.6;

        &::after {
            @apply block clear-both;
            content: '';
        }

        h1,h2,h3,h4,h5,h6 {
            padding-top: .5em;
            padding-bottom: .125em;
        }

        p {
            padding: .5em 0;
        }

        img {
            @apply max-w-full h-auto box-border;
        }

        var {
            @apply not-italic;
            padding: 0 .125em;
            color: #e06c75;
            background-color: #eee;
        }
        a {
            @apply cursor-pointer;
            color: #2e87e7;

            &:hover {
                @apply underline;
            }

            &:visited {
                color: #609;
            }
        }

        pre {
            @apply whitespace-pre-wrap;
            margin: .5em 0;
            padding: 1em;
            background-color: #303133;
            color: #d9ecff;
            border-radius: 5px;
        }

        ul, ol {
            padding: .5em 0 .5em 2em;

            li {
                padding: .5em 0 .5em .25em;

                &:first-child:last-child,
                > *:first-child:last-child {
                    @apply p-0;
                }
            }
        }

        ul {
            @apply list-disc;
        }

        ol {
            @apply list-decimal;
            padding-left: 2em;
        }

        blockquote {
            @apply inline-block box-border;
            margin: .5em 0 .5em 0;
            border-left: .25rem solid #ccc;
            padding: 1em;
            width: calc(100% - .5em);

            small {
                @apply italic;
            }
        }

        .table-wrapper {
            @apply max-w-full overflow-x-auto overflow-y-hidden;
            margin: .5em 0;
            padding: 2px 0;
        }

        table {
            @apply whitespace-normal break-normal w-full;
            border-spacing: 0;
            border-top: 1px dashed #e1e1e1;
            border-left: 1px dashed #e1e1e1;

            td, th {
                @apply text-left;
                border-right: 1px dashed #e1e1e1;
                border-bottom: 1px dashed #e1e1e1;
                padding: 1.2rem 1.5rem;
            }
        }

        .image-wrapper {
            @apply cursor-pointer;

            &--float-left {
                @apply float-left;
                margin: 14px 14px 14px 0
            }

            &--float-right {
                @apply float-right;
                margin: 14px 0 14px 14px
            }

            &--inline {
                @apply inline-block;
                margin: 5px 10px
            }
        }

        .iframe-wrapper {
            margin: .5em 0;

            &--youtube, &--vimeo {
                @apply relative w-full h-0 min-h-0;
                padding-top: 56.25%;

                iframe {
                    @apply absolute top-0 left-0 w-full h-full min-h-0;
                }
            }

            &--quizlet {
                iframe {
                    @apply h-auto;
                    min-height: 400px
                }
            }

            &--office-forms {
                iframe {
                    @apply h-auto;
                    min-height: 400px
                }
            }
        }

        iframe {
            @apply border-none w-full;
            min-height: 300px
        }
    }
}
